import Vue from 'vue'

export default {
  getWithdrawals(data) {
    return Vue.axios.post('/dashboard/requests/list', data)
  },
  getReportOnWithdrawalMoneyFile(data) {
    return Vue.axios.post('/dashboard/requests/list/xlsx', data, {
      responseType: 'blob'
    })
  },
  approveWithdrawals(id) {
    return Vue.axios.patch(`/dashboard/requests/${+id}`, { statusId: 2 })
  },
  rejectWithdrawals(id) {
    return Vue.axios.patch(`/dashboard/requests/${+id}`, { statusId: 3 })
  }
}
